import React, {useEffect, useState} from "react";

import {doc, getDoc} from 'firebase/firestore'
import {db} from '../../firebase'
import {isTestEnvironment} from "../utils";

interface Props{
    initialDocumentPath: string,
}


function useFirestore({initialDocumentPath}: Props): [{data: any, isLoading: boolean, isError: boolean}, React.Dispatch<React.SetStateAction<string>>]{
    const [data, setData] = useState<any>()
    const [docPath, setDocPath] = useState<string>(initialDocumentPath)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)

    useEffect(() => {
        const fetchData = async () => {
            if(!docPath || docPath === ''){return}
            setIsError(false)
            setIsLoading(true)

            const docRef = doc(db, docPath)
            const docSnap = await getDoc(docRef)

            if(isTestEnvironment()){
                console.log("Load data from ", docRef.firestore.app.name)
            }

            if(docSnap.exists()){
                setData(docSnap.data())
            }else{
                if(isTestEnvironment()){
                    console.log("DocSnap does not exists.", docPath)
                }
                setIsError(true)
            }
            setIsLoading(false)
        }

        fetchData().then()

        return () => {}
    }, [docPath])

    return [{data, isLoading, isError}, setDocPath]
}

export default useFirestore
